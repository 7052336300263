
                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                
















































































































.post-location-page {
  padding: 2rem;
}

h1 {
  text-align: center;
}

.row {
  margin-bottom: 1rem;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;

  .col {
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    margin: 0.5rem 0;
  }
}

.button {
  align-self: center;
  color: $sma-yellow;
  background-color: $sma-white;
  border: 1px solid $sma-yellow;
  border-radius: 10rem;
  padding: 0.5rem 2rem;
  margin-top: 5rem;
  width: 600px;
  max-width: 100%;
  position: relative;
}

.button:hover {
  background-color: $sma-yellow;
  color: $sma-white;
}

.button .tooltip {
  opacity: 0;
  width: 300px;
  background-color: $sma-blue;
  color: $sma-white;
  text-align: center;
  position: absolute;
  border-radius: 5px;
  font-size: 0.8rem;
  padding: 10px;
  z-index: 1;
  top: -5px;
  left: 105%;
  transition: opacity 0.25s;
}

.button:hover .tooltip {
  opacity: 1;
}

.button .tooltip::after {
  content: " ";
  position: absolute;
  top: 1.5rem;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent $sma-blue transparent transparent;
}

.fail {
  font-size: 0.875rem;
  color: red;
  margin-left: 1rem;
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                













































































































































































.form {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
}

.btn {
  padding: 0.25rem 0;

  &.facebook {
    color: $sma-white;
    background-color: $facebook-blue;
    border-color: $facebook-blue;
  }
}

.divider {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;

  .line {
    background-color: $sma-light-gray;
    height: 2px;
  }

  .text {
    align-self: center;
    margin-top: -0.75rem;
    padding: 0 1.5rem;
    background-color: $sma-white;
    font-size: 0.875rem;
  }
}

.input {
  border: 1px solid $sma-light-gray;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
  font-size: 0.875rem;
}

.link {
  color: $sma-blue;
  font-weight: 600;
}

.forgot-password {
  align-self: center;
  margin: 0.5rem 0;
  font-size: 0.875rem;
}

.signup {
  align-self: center;
  font-size: 0.875rem;
}

.error {
  margin: 0.5rem 0;
  font-size: 0.875rem;
  text-align: center;
  color: $sma-red;
}

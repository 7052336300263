
                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                




























































































.row {
  margin-bottom: 7rem;
}

.button {
  align-self: center;
  color: $sma-white;
  background-color: $sma-yellow;
  border-radius: 10rem;
  padding: 0.5rem 2rem;
  margin-bottom: 1rem;
}

.airports {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-left: -1rem;

  .airport {
    display: flex;
    align-items: center;
    border: 1px solid black;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 0.5rem;
    margin-left: 1rem;

    .text {
      align-content: center;
      font-size: 0.7rem;
      margin: 0 0.5rem;
      user-select: none;
    }

    .x {
      color: $sma-white;
      background: $sma-yellow;
      font-size: 1rem;
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}

.col.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.del-button {
  background-color: $sma-red;
  margin-left: 2em;
}

.error-message {
  margin: 0.5rem 0;
  font-size: 0.875rem;
  color: $sma-red;
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                











































































































.instructor-card {
  display: flex;
  flex-direction: column;
  background-color: $sma-white;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
  width: 100%;
  text-align: left;
  align-items: stretch;
  border: 0;
  cursor: pointer;

  .image {
    width: 100%;
    background-color: $sma-near-white;
  }

  .details {
    display: flex;
    flex-direction: column;
    background-color: $sma-white;
    padding: 0.5rem;

    .title {
      font-weight: 600;
    }

    .rate {
      flex: 1;
      font-size: 0.875rem;
      margin-bottom: 1rem;
    }

    .location {
      display: flex;
      font-size: 0.875rem;

      .marker {
        color: $sma-blue;
        margin-right: 0.5rem;
        margin-top: 0.125rem;
      }

      .address {
        color: $sma-dark-gray;
      }
    }
  }
}

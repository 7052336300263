// Colors
$sma-black: #000;
$sma-dark-gray: #757373;
$sma-gray: #999;
$sma-light-gray: #ddd;
$sma-near-white: #f9f9f9;
$sma-white: #fff;
$sma-yellow: #f8b80a;
$sma-red: #fb414d;
$sma-blue: #006eb0;
$sma-dark-blue: #0a3551;
$facebook-blue: #3b5998;
$youtube-red: #f00;
$vimeo-blue: #1ab7ea;

// Grid
$grid-num-cols: 24;

$grid-gutter-width: 1rem;

$grid-max-width-sm: 45em;
$grid-max-width-md: 60em;
$grid-max-width-lg: 71em;

$breakpoint-sm: 48em;
$breakpoint-md: 62em;
$breakpoint-lg: 75em;


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                











































































































































































#file-input {
  display: none;
}

input[type="text"] {
  border: 1px solid $sma-light-gray;
  border-radius: 0.25rem;
  width: 300px;

  &::placeholder {
    color: #aaa;
  }

  &.text {
    margin-top: 1rem;
    padding: 0.25rem 0.5rem;
    font-size: 1em;
    font-weight: 600;

    &:focus {
      border-color: $sma-blue;
    }
  }
}

.buttons {
  margin: -1em 0;
}

.btn {
  display: block;
  width: 200px;
  margin-top: 2rem;
}

.image-button {
  background-color: $sma-yellow;
  border: 1px solid $sma-yellow;
}

.youtube-btn {
  color: $sma-white;
  background-color: $youtube-red;
  border: 1px solid $youtube-red;
}

.vimeo-btn {
  color: $sma-white;
  background-color: $vimeo-blue;
  border: 1px solid $vimeo-blue;
}

.link {
  display: flex;
  align-items: center;
  flex-direction: column;
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                









































































































.shrink {
  width: 90%;
  float: left;
  margin-top: 1.2rem;
  margin-right: 1rem;
  margin-left: 1rem;
  font-weight: bold;
}

.btn {
  font-weight: bold;
  position: relative;
  // width: 50%;
  left: 25%;
  // margin-left: 12rem;
  margin-top: 4rem;
}

.videos img {
  width: 90%;
  height: auto;
  margin-bottom: 2rem;
  margin-right: 1rem;
}

a.video {
  float: left;
  position: relative;
}

a.video span {
  width: 90%;
  height: 90%;
  position: absolute;
  background: url(https://img.icons8.com/color/480/000000/youtube-play.png) no-repeat;
  background-position: 50% 45%;
  background-size: 20%;
}

@media screen and (max-width: 480px) {
  a.video span {
    background-size: 20%;
  }
}

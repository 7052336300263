
                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                
















































































































































































































































































































.row {
  margin-bottom: 7rem;
}

.button {
  align-self: center;
  color: $sma-white;
  background-color: $sma-yellow;
  border-radius: 10rem;
  padding: 0.5rem 2rem;
  margin-bottom: 1rem;
}

.tabs {
  .button {
    cursor: pointer;
    display: block;
    width: 100%;
    margin-top: 1rem;
    text-align: center;
    background-color: $sma-yellow;
    color: $sma-white;
    border: 1px solid $sma-white;

    &.inactive {
      background-color: $sma-white;
      color: $sma-yellow;
      border: 1px solid $sma-yellow;
    }
  }
}

.col.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.del-button {
  background-color: $sma-red;
  margin-left: 2em;
}

.error-message {
  margin: 0.5rem 0;
  font-size: 0.875rem;
  color: $sma-red;
}

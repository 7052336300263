
                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                




















































































































































































































































.container {
  background-color: $sma-white;
}

.content {
  padding: 2rem;
}

.close {
  float: right;
  color: $sma-gray;
  font-size: 1.5rem;
}

.image {
  background-color: $sma-near-white;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 4px;
  margin: 1rem 0;

  button,
  a {
    cursor: pointer;
    font-size: 0.9em;
    font-weight: 400;
    color: $sma-blue;
    border: 0;
    background: transparent;
    padding: 0;
    display: flex;
    align-items: center;
  }

  .icon-container {
    display: inline-flex;
    justify-content: center;
    align-items: baseline;
    width: 1.4em;
    height: 100%;
    margin-right: 0.2em;
  }
}

.about-me {
  font-size: 0.875rem;
}

.pair {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  padding: 0.25rem;
  flex-wrap: wrap;
}

.name {
  font-weight: 600;
  margin-right: 0.5em;
  min-width: 100px;
  text-align: right;
}

.value {
  flex: 1;
  color: $sma-gray;

  & input {
    width: 100%;
    border-bottom: 1px solid $sma-gray;
    color: $sma-gray;
  }
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                










































































































































































.blog-page {
  .col {
    margin-bottom: 2rem;
  }
}

.container {
  padding: 0 2rem;
  margin-bottom: 64px;

  @media (min-width: $breakpoint-sm) {
    padding: 0 5rem;
  }
}

.post-previews {
  margin-top: 32px;
}

.post-preview {
  .post-preview-img-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
  }

  .post-preview-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.pagination {
  text-align: center;
  font-size: 24px;
  margin-top: 32px;

  .current-page {
    font-size: 1.2em;
    font-weight: 400;
  }
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                





























































































































































































































































































































































.signup {
  margin-bottom: 50px;
}

.header {
  color: $sma-blue;
  margin-bottom: 2rem;
}

.left-side {
  @media only screen and (max-width: $breakpoint-sm) {
    display: none;
  }
}

.bullets {
  margin-top: 1rem;

  .bullet {
    margin-bottom: 1rem;

    .check {
      color: #42d577;
      font-size: 2rem;
      margin-top: -4px;
    }

    .bullet-body {
      .bullet-title {
        color: $sma-blue;
        margin: 0;
        height: 2rem;
        display: flex;
        align-items: center;
      }
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 90%;
  margin: 0 auto;

  /*
  @media only screen and (max-width: $breakpoint-sm) {
    max-width: 90%;
  }
  */
}

.button {
  cursor: pointer;
  padding: 0.25rem 4rem;
  margin: 0.5rem 0;
  border-style: solid;
  border-width: 1px;
  border-radius: 10rem;
  white-space: nowrap;
  background-color: $sma-white;
  text-align: center;

  &.facebook {
    color: $sma-white;
    background-color: $facebook-blue;
    border-color: $facebook-blue;
  }

  &.signup {
    color: $sma-white;
    background: $sma-red;
    border-color: $sma-red;
  }
}

.divider {
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  align-items: center;

  .line {
    background-color: $sma-light-gray;
    height: 2px;
    width: 100%;
  }

  .text {
    align-self: center;
    margin-top: -0.25rem;
    padding: 0 1.5rem;
    font-size: 0.875rem;
    flex-shrink: 0;
  }
}

.input {
  border: 1px solid $sma-light-gray;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
  font-size: 0.875rem;

  @media only screen and (max-width: $breakpoint-sm) {
    width: 100%;
  }
}

.link {
  color: $sma-blue;
  font-weight: 600;
}

.forgot-password {
  align-self: center;
  margin: 0.5rem 0;
  font-size: 0.875rem;
}

.login {
  align-self: center;
  font-size: 0.875rem;
}

.error-message {
  margin: 0.5rem 0;
  font-size: 0.875rem;
  color: $sma-red;
}

.input-row {
  display: flex;

  @media only screen and (max-width: $breakpoint-sm) {
    display: block;
  }
}

.first-name {
  flex: 1;
  margin-right: 0.5rem;

  @media only screen and (max-width: $breakpoint-sm) {
    margin-right: 0;
  }
}

.last-name {
  flex: 1;
  margin-left: 0.5rem;

  @media only screen and (max-width: $breakpoint-sm) {
    margin-left: 0;
  }
}

input.error {
  border-color: red;
}

.media-container {
  position: relative;
}

.media {
  margin: 0.5rem 0;
}

.x-button {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2rem;
  cursor: pointer;
}

#email {
  position: absolute;
  left: -10000vw;
  top: -10000vh;
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                






























































































































.row {
  width: 100%;
}

.form {
  margin-top: 2rem;
}

label {
  text-align: left;

  @media only screen and (min-width: 48em) {
    text-align: right;
  }
}

.btn {
  margin: 5rem auto 0;
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                












































































































































































































































































































































.form {
  background-color: $sma-white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  padding: 0.5rem 1rem;
  border: 0 solid $sma-light-gray;
  border-top-width: 1px;

  .col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;

    @media only screen and (max-width: $breakpoint-sm) {
      padding: 0.5rem 1rem;
      margin: 0;

      &:nth-child(3) {
        margin-top: 1rem;
      }
    }

    .btn {
      @media only screen and (max-width: $breakpoint-sm) {
        margin: 0 0.5rem;
      }
    }
  }

  .desktop-hide {
    display: none;

    @media only screen and (max-width: $breakpoint-sm) {
      display: inherit;
    }
  }
}

.rate {
  max-width: 100%;
}

.sort {
  margin-top: 1rem;
}

.label {
  color: $sma-gray;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
}

.input {
  border: 1px solid $sma-light-gray;
  border-radius: 0.25rem;
  padding: 0.5rem;

  &.input {
    width: 100%;
    background: #fff;
    border-radius: 10em;
    font-size: 1.25em;
    padding: 0.5em 1em;
  }

  &.model {
    width: 8rem;

    @media only screen and (max-width: $breakpoint-lg) {
      width: 100%;
    }
  }
}

.no-instrtuctors {
  text-align: center;
  padding-bottom: 3rem;
}

.instructors {
  margin: 0;

  .col {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .mobile-hide {
    display: inherit;

    @media only screen and (max-width: $breakpoint-sm) {
      display: none;
    }
  }
}

.dropdown {
  display: flex;
  align-items: center;
  color: $sma-gray;
  font-size: 0.875rem;
  border: 1px solid $sma-gray;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  width: 100%;

  .text {
    margin-right: 1rem;
  }
}

.specialties-btn {
  position: relative;

  .specialties {
    position: absolute;
    background-color: $sma-white;
    padding: 1rem;
    z-index: 999;
    top: 100%;

    .drop {
      display: flex;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      input {
        width: auto;
        margin: 0 4px 0 -4px;
      }
    }
  }
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                






































































































































































































































.footer {
  background: $sma-dark-blue;
  color: $sma-white;
  position: relative;

  @media only screen and (max-width: $breakpoint-sm) {
    padding: 0 0.5rem;
  }

  &::before {
    content: '';
    height: 50px;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: -50px;
    background:
      linear-gradient(
        rgba(
          255,
          255,
          255,
          0
        ),
        #fff
      );
  }

  .testimonials {
    background: #fff url('~@/assets/testimonial-bg.jpg') top/100% auto no-repeat;
    position: relative;

    .container {
      position: relative;
    }

    .slider-container {
      width: 300px;
      position: absolute;
      top: 0;
      right: 10%;

      @media (min-width: $breakpoint-md) {
        width: 500px;
      }
    }

    .pre,
    .next {
      display: block;
      width: 13px;
      cursor: pointer;
      position: absolute;
      top: 50%;
    }

    .pre {
      background-image: url('~@/assets/pre.png');
      height: 33px;
      right: calc(10% + 320px);

      @media (min-width: $breakpoint-md) {
        right: calc(10% + 520px);
      }
    }

    .next {
      background-image: url('~@/assets/next.png');
      height: 35px;
      right: calc(10% - 25px);
    }
  }

  .copy {
    padding: 0.5em 0;

    & a,
    & p {
      color: $sma-light-gray;
      transition: color 0.3s;
      font-size: 0.8rem;
    }

    & a:hover {
      color: $sma-blue;
    }

    .credit {
      float: right;
    }
  }

  .top {
    padding: 2em 0;

    .col a {
      display: flex;
      align-items: center;
      justify-content: left;
    }

    & img {
      max-height: 64px;
      max-width: 64px;
      margin: 0 1em 0 0;
    }

    & h1 {
      font-size: 36px;
      color: $sma-white;
    }
  }

  .bottom {
    padding: 2em 0;

    .col {
      & a {
        display: block;
        color: $sma-white;
        margin: 1em 0;
        transition: color 0.3s;

        &:hover {
          color: $sma-blue;
        }
      }

      & h2 {
        transition: color 0.3s;
      }
    }

    .social {
      display: flex;

      .linkedin {
        background: url('~@/assets/social.png');
        background-size: 400%;
        display: block;
        height: 32px;
        width: 32px;
        margin: 0 0.5em;

        &:hover {
          background: url('~@/assets/social-hover.png');
        }
      }

      .facebook {
        background: url('~@/assets/social.png');
        background-size: 400%;
        background-position: -100% 0;
        display: block;
        height: 32px;
        width: 32px;
        margin: 0 0.5em;

        &:hover {
          background: url('~@/assets/social-hover.png');
          background-position: -100% 0;
        }
      }

      .twitter {
        background: url('~@/assets/social.png');
        background-size: 400%;
        background-position: -200% 0;
        display: block;
        height: 32px;
        width: 32px;
        margin: 0 0.5em;

        &:hover {
          background: url('~@/assets/social-hover.png');
          background-position: -200% 0;
        }
      }

      .instagram {
        background: url('~@/assets/social.png');
        background-size: 400%;
        background-position: -300% 0;
        display: block;
        height: 32px;
        width: 32px;
        margin: 0 0.5em;

        &:hover {
          background: url('~@/assets/social-hover.png');
          background-position: -300% 0;
        }
      }
    }
  }
}

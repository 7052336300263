
                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                




























































































































































































































.container {
  padding-bottom: 32px;

  &.row {
    margin-bottom: 7rem;
  }
}

.button {
  align-self: center;
  color: $sma-white;
  background-color: $sma-yellow;
  border-radius: 10rem;
  padding: 0.5rem 2rem;
  margin-bottom: 1rem;
}

.tabs {
  .button {
    cursor: pointer;
    display: block;
    width: 100%;
    margin-top: 1rem;
    text-align: center;
    background-color: $sma-yellow;
    color: $sma-white;
    border: 1px solid $sma-white;

    &.inactive {
      background-color: $sma-white;
      color: $sma-yellow;
      border: 1px solid $sma-yellow;
    }
  }
}

.sub-options {
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.spacer {
  flex: 1 1 auto;
}

.col.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.del-button {
  background-color: $sma-red;
  margin-left: 2em;
}

h2 {
  text-align: center;
}

.btn-container {
  text-align: center;
}

.instructions {
  margin-bottom: 16px;
}

.desc {
  margin-bottom: 8px;
}

.exp-msg {
  margin-bottom: 16px;
}

.sub-btns {
  text-align: center;
}

.instructor-acct {
  margin-top: 16px;

  .text {
    margin-bottom: 8px;
  }

  .btn-container {
    display: flex;
    justify-content: center;
  }
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                





























.laptop-img {
  position: relative;
  width: 100%;
}

.laptop {
  display: block;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  filter: drop-shadow(0 8px 16px #0005);
}

.img {
  position: absolute;
  top: 4.5%;
  left: 15.2%;
  right: 14.9%;
  bottom: 28.4%;
  border-radius: 2px;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                






































































































































































































































































































































































































































































































.header {
  position: relative;
  display: flex;
  align-items: center;
  background-color: $sma-white;
  padding: 0 1rem;
  z-index: 20;
  flex-wrap: wrap;
  position: sticky;
  top: 0;

  @media only screen and (min-width: $breakpoint-lg) {
    position: inherit;
  }

  @media only screen and (max-width: $breakpoint-sm) {
    padding: 0.5rem 1rem;
  }
}

.logo {
  height: 2.6rem;
  display: inherit;

  @media only screen and (max-width: $breakpoint-lg) {
    height: 2rem;
    display: none;
  }
}

.mobile-logo {
  display: inherit;
  height: 2rem;

  @media only screen and (min-width: $breakpoint-lg) {
    display: none;
  }
}

.gap {
  flex: 1;
  min-width: 1rem;
}

.links {
  display: flex;

  @media only screen and (max-width: $breakpoint-lg) {
    position: absolute;
    flex-direction: column;
    right: 0;
    top: 100%;
    background: $sma-white;
    width: 100%;
    height: 650px;
    overflow-x: auto;
  }

  .drop {
    display: none;
    position: absolute;
    top: 100%;
    left: -8px;
    flex-direction: column;
    background: $sma-white;
    z-index: 1;
    width: 230px;

    @media only screen and (max-width: $breakpoint-lg) {
      display: flex;
      width: 100%;
      position: relative;
      font-size: 0.8rem;
      top: 0;

      .link {
        font-weight: 500;
        font-stretch: normal;
      }
    }
  }

  .has-drop {
    position: relative;

    &:hover .drop {
      display: flex;
    }
  }
}

.nav-toggle {
  display: flex;
  height: 1.25rem;
  width: 1.5rem;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 3em;
  cursor: pointer;
  position: absolute;
  top: 0.5em;
  right: 2em;

  span {
    display: block;
    background-color: $sma-yellow;
    height: 0.25rem;
    width: 100%;
  }

  #close-left {
    transform: translate(0, 10px) rotate(45deg);
  }

  #close-right {
    transform: translate(0, -8px) rotate(135deg);
  }

  @media only screen and (min-width: $breakpoint-lg) {
    display: none;
  }

  @media only screen and (max-width: $breakpoint-sm) {
    top: 0.9em;
  }
}

.link {
  padding: 1rem;
  margin: 0;

  /* font-family:
    'HelveticaNeue-CondensedBold',
    'Helvetica Neue',
    sans-serif; */
  font-stretch: condensed;
  position: relative;
  color: $sma-blue;
  font-family: Raleway, 'Gotham Narrow A', 'Gotham Narrow B', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  text-transform: uppercase;

  @media only screen and (max-width: $breakpoint-lg) {
    padding: 0.5rem;
  }

  &.active {
    color: $sma-yellow;

    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 0.2rem;
      width: calc(100% - 2rem);
      background: $sma-yellow;
    }
  }
}

.links > .link {
  @media only screen and (max-width: $breakpoint-lg) {
    font-size: 18px;
    font-weight: bold;
  }
}

.buttons {
  display: flex;
  align-items: center;

  @media only screen and (max-width: $breakpoint-lg) {
    margin-right: 3em;
  }
}

.outer-logout {
  cursor: pointer;
  font-size: 0.75rem;
  padding: 0.5rem;
  color: $sma-blue;

  @media screen and (max-width: $breakpoint-lg) {
    display: none;
  }
}

.avatar {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border: 0;
  border-radius: 50%;
  color: $sma-white;
  background-color: $sma-yellow;
  font-size: 0.75rem;
  margin-left: 0.5rem;
  overflow: hidden;
  padding: 0;
}

.user-nav {
  position: absolute;
  top: 100%;
  right: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  background-color: $sma-white;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
  font-size: 0.875rem;

  .link {
    display: flex;
    align-items: center;
    padding: 0.25rem;
  }

  .icon {
    margin-right: 0.5rem;
  }
}

.avatar-image {
  width: 100%;
  height: 100%;
}

.logout-btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                

















































.content {
  margin-top: 1em;
}

.modal {
  text-align: center;
}

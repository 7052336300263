
                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                











































































































.question {
  margin-bottom: 24px;
}

.q {
  font-weight: 600;
  font-size: 1.2em;
  color: $sma-blue;
}

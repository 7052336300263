@import './aspect-ratios.scss';
@import './utils.scss';
@import './grid.scss';

* {
  box-sizing: border-box;
}

html {
  font-size: 18px;
  height: 100%;
}

body {
  height: auto;
  overflow: auto !important;
  margin: 0;
  padding: 0;
  font-family: 'Gotham Narrow A', 'Gotham Narrow B', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  letter-spacing: 0.8px;
  color: #414141;
  font-weight: 200;
}

.page {
  background: url('~@/assets/bg.png');
  background-size: 100% auto;
  background-repeat: repeat-y;

  &.info-page {
    padding: 2em;
  }
}

a {
  cursor: pointer;
  color: $sma-blue;
  text-decoration: none;
}

p {
  line-height: 1.5;
}

h1 {
  color: $sma-blue;
  text-transform: uppercase;
}

h2 {
  color: $sma-blue;
}

strong {
  color: $sma-blue;
  font-weight: 500;
}

textarea {
  font-family: inherit;
}

li {
  line-height: 1.5;
}

ul {
  margin: 0 0 1rem;
}

ol {
  list-style-type: none;
  margin-left: 3rem;
  padding: 0;
  counter-reset: num;

  li {
    position: relative;
    margin-top: 1rem;

    &::before {
      counter-increment: num;
      content: '0' counter(num);
      display: inline-block;
      border-radius: 50px;
      background: $sma-blue;
      height: 1.5rem;
      width: 1.5rem;
      color: $sma-white;
      text-align: center;
      padding: 4px;
      position: absolute;
      top: -0.3rem;
      left: -2.5rem;
    }
  }
}

.btn {
  cursor: pointer;
  color: $sma-yellow;
  font-size: 1em;
  text-align: center;
  background-color: transparent;
  border: 1px solid $sma-yellow;
  border-radius: 9999px;
  padding: 0.5rem 2rem;
  margin: 0.5rem;
  white-space: nowrap;

  &.dense {
    padding: 0.25rem 1rem;

    @media only screen and (max-width: $breakpoint-sm) {
      padding: 0.25rem 0.4rem;
      margin: 0.1rem;
      font-size: 0.8em;
    }
  }

  &.round {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
  }

  &.sm-wide {
    @media only screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }

  &.yellow {
    color: $sma-white;
    background-color: $sma-yellow;
    border-color: $sma-yellow;

    &.inv {
      color: $sma-yellow;
      background-color: transparent;
      border-color: $sma-yellow;
    }
  }

  &.blue {
    color: $sma-white;
    background-color: $sma-blue;
    border-color: $sma-blue;

    &.inv {
      color: $sma-blue;
      background-color: transparent;
      border-color: $sma-blue;
    }
  }

  &.red {
    color: $sma-white;
    background-color: $sma-red;
    border-color: $sma-red;

    &.inv {
      color: $sma-red;
      background-color: transparent;
      border-color: $sma-blue;
    }
  }
}

.input {
  color: $sma-dark-gray;
  font-size: 1em;
  border: 1px solid $sma-light-gray;
  border-radius: 0.25rem;
  outline: none;
  margin: 0;
  padding: 0;

  &::placeholder {
    color: #bbb;
    font-weight: 400;
  }

  &.text {
    padding: 0.25rem 0.5rem;
    font-weight: 600;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &:focus {
      border-color: $sma-blue;
    }
  }
}

.form .col {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  margin: 0.5rem 0;

  .label {
    margin-bottom: 0.25rem;
  }

  &.horizontal {
    flex-direction: row;
    align-items: center;

    .label {
      margin-bottom: 0;
      margin-left: 1rem;
    }
  }
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  padding: 2rem 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9999;

  .modal {
    position: relative;
    overflow: hidden auto;
    background: $sma-white;
    max-height: 100%;
    margin: 0 auto;
    max-width: 90%;

    @media screen and (max-width: $breakpoint-sm) {
      min-width: 90%;
    }

    .content {
      width: 100%;
      height: 100%;
      padding: 2rem;
      overflow-y: auto;
    }

    .close-btn {
      cursor: pointer;
      position: absolute;
      top: 0.5em;
      right: 0.5em;
      color: $sma-gray;
      font-size: 1.5em;
      border: 0;
      background-color: transparent;
      padding: 0;
    }
  }
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                































































































































































































































































































































































































































































































































































































































































































































































































































.find-page {
  position: relative;
  height: 100%;
}

.find-page > .row {
  /* height: 100vh; */
  padding-bottom: 2rem;

  /* @media only screen and (max-width: $breakpoint-sm) {
    height: auto;
  } */
}

.find-page {
  @media only screen and (max-width: $breakpoint-sm) {
    height: auto;
  }
}

.col.map-container {
  min-height: 400px;

  /* height: 96vh;

  @media only screen and (max-width: $breakpoint-sm) {
    height: calc(100vh - 100px);
  } */
}

.map {
  height: 100%;
}

.col {
  position: relative;
  height: 100%;
  background-color: $sma-near-white;
}

.form {
  position: absolute;
  top: 1rem;
  left: 1.5rem;
  right: 1.5rem;
  display: flex;
  background-color: $sma-white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  z-index: 99;

  @media only screen and (max-width: $breakpoint-lg) {
    flex-wrap: wrap;
  }

  .col {
    background: transparent;
  }
}

.close-btn {
  cursor: pointer;
  position: absolute;
  top: 0.1em;
  right: 0.1em;
  color: $sma-gray;
  font-size: 1.5em;
  border: 0;
  background-color: transparent;
}

.view-all-btn {
  position: absolute;
  bottom: 1rem;
  left: calc(50% - 100px);
  width: 200px;

  @media only screen and (max-width: $breakpoint-sm) {
    top: 0;
    left: 3.5em;
  }
}

.home-btn {
  position: absolute;
  bottom: 1rem;
  left: 10rem;
}

.zoom-btns {
  position: absolute;
  bottom: 1rem;
  right: 0.5rem;
  display: flex;
  flex-direction: column;
}

.show-search-btn {
  position: absolute;
  top: 0;
  left: 0.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

.label {
  color: $sma-gray;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
}

.input {
  border: 1px solid $sma-light-gray;
  border-radius: 0.25rem;
  padding: 0.5rem;
  width: 100%;

  @media only screen and (max-width: $breakpoint-sm) {
    margin: 0.5rem 0;
  }

  &.address {
    width: 100%;
    background: #fff;
    border-radius: 10em;
    font-size: 1.5em;
    padding: 0.2em 0.5em;
  }

  &.model {
    width: 8rem;

    @media only screen and (max-width: $breakpoint-lg) {
      width: 100%;
    }
  }
}

.amount {
  text-align: center;
}

.results {
  flex: 1;
  padding: 1rem;
  height: 100%;
  overflow: auto;
}

.stats {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.dropdown {
  display: flex;
  align-items: center;
  color: $sma-gray;
  font-size: 0.875rem;
  border: 1px solid $sma-gray;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;

  .text {
    margin-right: 1rem;
  }
}

.share {
  margin: 1rem 0;
}

.no-shares {
  text-align: center;
}

.col-flex {
  @media only screen and (max-width: $breakpoint-lg) {
    height: auto;
  }
}

.categories-btn {
  position: relative;

  .categories {
    position: absolute;
    background-color: $sma-white;
    top: 100%;
    padding: 1rem;
    z-index: 999;

    .drop {
      display: flex;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      & input {
        width: auto;
        margin: 0 4px 0 -4px;
      }
    }
  }
}

.search-types {
  @media (min-width: $breakpoint-lg) {
    height: 58px;
  }

  & > .col {
    align-items: center;
    height: auto;

    @media (min-width: $breakpoint-lg) {
      align-items: start;
      margin: 0;

      /* margin-top: 4px; */
    }

    & > * {
      width: fit-content;
    }
  }
}

.legend {
  margin-top: 0;
  flex-direction: column;
  position: absolute;
  bottom: 1.5rem;
  left: 1rem;
  padding: 1rem;
  background-color: $sma-white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

  .header {
    font-weight: 700;
  }

  .content {
    display: flex;
    margin-top: 8px;
    flex-direction: column;

    .type {
      display: flex;
      align-items: center;

      &:not(:last-of-type) {
        margin-bottom: 0;
        margin-right: 8px;

        @media (min-width: $breakpoint-md) {
          margin-bottom: 8px;
          margin-right: 0;
        }
      }

      img {
        height: 32px;
        width: 32px;
        margin-right: 8px;
      }
    }
  }
}

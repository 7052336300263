
                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                


























































.takeover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  padding: 5rem 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 30;
}

.close {
  position: absolute;
  color: $sma-gray;
  font-size: 1.5rem;
  top: 0;
  right: 0.5rem;

  &:hover {
    color: $sma-white;
  }
}

.modal {
  position: relative;
  max-width: 95%;
  background-color: $sma-white;

  & img {
    width: 100%;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  margin: 1rem 0;

  .button {
    color: $sma-white;
    background-color: $sma-yellow;
    padding: 0.5rem 2rem;
    border-radius: 10rem;

    &.find-button {
      color: $sma-yellow;
      background-color: $sma-white;
      border: 1px solid $sma-yellow;
    }
  }

  .space {
    width: 1rem;
  }
}

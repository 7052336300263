
                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                













































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.post-general-page {
  padding: 2rem;
}

.row {
  margin-bottom: 1rem;
}

.form .col {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  margin: 0.5rem 0;

  .label {
    margin-bottom: 0.25rem;
  }

  &.horizontal {
    flex-direction: row;
    align-items: center;

    .label {
      margin-bottom: 0;
      margin-left: 1rem;
    }
  }

  .input {
    border: 1px solid $sma-light-gray;
    border-radius: 0.25rem;
    background-color: white;

    &::placeholder {
      color: #ccc;
      font-weight: 400;
    }

    &.text {
      padding: 0.25rem 0.5rem;
      font-weight: 600;

      &:focus {
        border-color: $sma-blue;
      }
    }
  }

  .btn {
    align-self: center;
    margin-top: 5rem;

    &.tailNumber {
      margin-top: 20px;
    }
  }
}

#instrumentRatingRequired {
  margin-top: 1.5rem;

  & + label {
    margin-top: 1.5rem;
  }
}

.fail {
  font-size: 0.875rem;
  color: red;
  margin-left: 1rem;
}

#email {
  position: absolute;
  left: -10000vw;
  top: -10000vh;
}

.req {
  color: $sma-red;
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                




























































































































































h2 {
  color: $sma-blue;
  font-size: 32px;
  margin: 12px 0;
}

.form {
  @media only screen and (max-width: $breakpoint-sm) {
    max-width: 90%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto !important;
}

.error-message {
  margin: 0.5rem 0;
  font-size: 0.875rem;
  color: $sma-red;
}

.signup-btn {
  margin-bottom: 16px;
}

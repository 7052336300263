
                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                




















































































































































































































































































































.post-confirm-page {
  padding: 2rem;
}

.row {
  margin-bottom: 2rem;
}

.media {
  margin: 0.5rem 0;
}

[default-media] {
  box-shadow: 0 0 20px 10px $sma-blue;
}

.nav .col {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  margin: 0.5rem 0;

  .button {
    align-self: center;
    margin-top: 3rem;
  }
}

p {
  color: $sma-gray;

  & strong {
    color: $sma-black;
  }
}

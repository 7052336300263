
                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                















































.tabs {
  .button {
    align-self: center;
    color: $sma-white;
    background-color: $sma-yellow;
    border-radius: 10rem;
    padding: 0.5rem 2rem;
    margin-bottom: 1rem;
    cursor: pointer;
    display: block;
    width: 100%;
    margin-top: 1rem;
    text-align: center;

    &.ghost {
      background-color: transparent;
      border: 1px solid $sma-yellow;
      color: $sma-yellow;
    }
  }
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                
































































































































.post-location-page {
  padding: 2rem;
}

.row {
  margin-bottom: 1rem;
}

.form .col {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  margin: 0.5rem 0;

  .label {
    margin-bottom: 0.25rem;
  }

  .input {
    border: 1px solid $sma-light-gray;
    border-radius: 0.25rem;

    &.text {
      padding: 0.25rem 0.5rem;
      font-weight: 600;

      &:focus {
        border-color: $sma-blue;
      }
    }
  }

  .btn {
    align-self: center;
    margin-top: 5rem;

    @media only screen and (max-width: $breakpoint-sm) {
      margin-top: 0.5rem;
      width: 100%;
    }
  }
}

.fail {
  font-size: 0.875rem;
  color: red;
  margin-left: 1rem;
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                







































































































.excel::before {
  content: '';
  display: block;
  height: 2rem;
  width: 2rem;
  background: url('~@/assets/excel.png');
  background-size: contain;
  float: left;
  margin-right: 0.5rem;
}

.partner-text {
  margin-top: 64px;
}

.partner-table {
  text-align: left;

  tr,
  td {
    padding: 8px 16px;
  }
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                






















































































































.row {
  margin-bottom: 1rem;
}

.form {
  margin: 5rem auto 0.5rem;

  .col {
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;

    .label {
      margin-bottom: 0.25rem;
    }

    &.horizontal {
      flex-direction: row;
      align-items: center;

      .label {
        margin-bottom: 0;
        margin-left: 1rem;
      }
    }

    .input {
      border: 1px solid $sma-light-gray;
      border-radius: 0.25rem;

      &::placeholder {
        color: #aaa;
      }

      &.text {
        padding: 0.25rem 0.5rem;
        font-weight: 600;

        &:focus {
          border-color: $sma-blue;
        }
      }
    }

    .button {
      align-self: center;
      color: $sma-white;
      background-color: $sma-yellow;
      border-radius: 10rem;
      padding: 0.5rem 2rem;
      margin-bottom: 5rem;

      &.tailNumber {
        margin-top: 20px;
      }
    }
  }
}

.error-message {
  margin: 0.5rem 0;
  font-size: 0.875rem;
  color: $sma-red;
}

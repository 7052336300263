
                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                






























































































































































































































































































































































.modal {
  width: 500px;
  overflow: visible !important;
  margin-top: 75px !important;
}

.starburst-container {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
}

.starburst {
  position: absolute;
  top: -75px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;

  .bg {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  .text {
    z-index: 2;
    text-align: center;
    color: $sma-white;
    font-weight: 500;
    border: 1px dashed $sma-white;
    border-radius: 999px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    height: 80%;

    .h1 {
      font-size: 14px;
    }

    .h2 {
      font-size: 20px;
    }

    &.instructor {
      .h1 {
        font-size: 28px;
        font-weight: 700;
      }
    }
  }
}

.content {
  padding-top: 75px !important;
  text-align: center;

  .input {
    height: 38px;
    background: $sma-white;
    border: 1px solid $sma-light-gray;
    border-radius: 5px;
    padding: 8px;
  }

  .h1,
  .h2 {
    color: $sma-blue;
    font-weight: 600;
  }

  .h1 {
    font-size: 24px;
  }

  .h2 {
    font-size: 18px;
    margin-bottom: 1rem;
  }

  .body {
    margin-bottom: 1rem;
  }

  .ccs {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    .cc {
      width: 42px;

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  .error-msg {
    margin: 0.5rem 0;
    font-size: 0.875rem;
    color: $sma-red;
  }

  .footnote {
    font-size: 12px;
    margin-bottom: 1rem;
  }
}

.button {
  display: block;
  cursor: pointer;
  padding: 0.5rem 4rem;
  margin: 0.5rem auto;
  border: 1px solid $sma-yellow;
  color: $sma-yellow;
  border-radius: 10rem;
  white-space: nowrap;
  text-align: center;
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                

































































































label {
  margin: 0.5rem 0 0.25rem;
  font-size: 0.875rem;
}

input,
textarea {
  display: block;
  width: 100%;
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  border: 1px solid $sma-light-gray;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;

  :focus {
    border-color: $sma-blue;
  }
}

textarea {
  height: 97px;
}

.submit-btn {
  cursor: pointer;
  width: 150px;
  margin: 0.25rem auto;
}

#email {
  position: absolute;
  left: -10000vw;
  top: -10000vh;
}

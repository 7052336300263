
                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                





















































































































































































































































































.search-card {
  display: flex;
  flex-direction: column;
  background-color: $sma-white;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
  width: 100%;
  text-align: left;
  align-items: stretch;
  border: 0;
  cursor: pointer;
  padding: 8px;
}

.image {
  width: 100%;
}

.details {
  display: block;
  flex: 1;
  flex-direction: column;

  .type {
    font-size: 16px;
    color: $sma-blue;
    font-weight: 600;
    display: flex;
    align-items: center;

    .icon {
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }
  }

  .title {
    margin-top: 8px;
    font-size: 18px;
    font-weight: 600;
  }

  .desc {
    margin-top: 8px;
    font-size: 16px;
  }

  .share-type {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 100;
  }

  .rate {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 100;
  }
}

.location {
  margin-top: 8px;
  display: flex;
  font-size: 0.875rem;

  .marker {
    color: $sma-blue;
    margin-right: 0.5rem;
    margin-top: 0.125rem;
  }

  .address {
    color: $sma-dark-gray;
  }
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                


















































































.container {
  padding: 0 2rem;

  @media (min-width: $breakpoint-sm) {
    padding: 0 5rem;
  }
}

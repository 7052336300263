
                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                


























































































.dropzone {
  border: 1px dashed $sma-gray;
  position: relative;

  input {
    width: 100%;
    opacity: 0;
    min-height: 8rem;
    cursor: pointer;
  }

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      text-align: center;
    }
  }
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                

























































.row {
  margin-bottom: 1rem;

  &.border {
    border: 0 solid $sma-light-gray;
    border-bottom-width: 1px;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0 1rem;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $sma-light-gray;
  transition: 0.4s;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
  }
}

input:checked + .slider {
  background-color: $sma-blue;
}

input:focus + .slider {
  box-shadow: 0 0 1px $sma-blue;
}

input:checked + .slider::before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;

  &::before {
    border-radius: 50%;
  }
}

.col.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  max-width: 100%;
  padding-left: $grid-gutter-width / 2;
  padding-right: $grid-gutter-width / 2;

  @media (min-width: $breakpoint-sm) {
    max-width: $grid-max-width-sm;
    margin: 0 auto;
  }

  @media (min-width: $breakpoint-md) {
    max-width: $grid-max-width-md;
    margin: 0 auto;
  }

  @media (min-width: $breakpoint-lg) {
    max-width: $grid-max-width-lg;
    margin: 0 auto;
  }
}

.row {
  min-width: 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.col {
  padding-left: $grid-gutter-width / 2;
  padding-right: $grid-gutter-width / 2;
  width: 100%;
}

.col-flex {
  display: flex;
}

.col-0 {
  display: none;
}

@for $i from 1 through $grid-num-cols {
  .col-#{$i} {
    width: calc(#{$i} / #{$grid-num-cols} * 100%);
  }
}

.col-auto {
  width: auto;
}

@for $i from 1 through $grid-num-cols {
  .col-sm-#{$i} {
    @media (min-width: $breakpoint-sm) {
      width: calc(#{$i} / #{$grid-num-cols} * 100%);
    }
  }
}

.col-sm-auto {
  @media (min-width: $breakpoint-sm) {
    width: auto;
  }
}

@for $i from 1 through $grid-num-cols {
  .col-md-#{$i} {
    @media (min-width: $breakpoint-md) {
      width: calc(#{$i} / #{$grid-num-cols} * 100%);
    }
  }
}

.col-md-auto {
  @media (min-width: $breakpoint-md) {
    width: auto;
  }
}

@for $i from 1 through $grid-num-cols {
  .col-lg-#{$i} {
    @media (min-width: $breakpoint-lg) {
      width: calc(#{$i} / #{$grid-num-cols} * 100%);
    }
  }
}

.col-lg-auto {
  @media (min-width: $breakpoint-lg) {
    width: auto;
  }
}

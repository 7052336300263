
                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                





































.progress {
  display: flex;

  .step {
    flex: 1;

    .bottom {
      margin-top: 0.5rem;
      margin-right: 2rem;

      .text {
        font-size: 0.75rem;
        color: $sma-gray;
        white-space: nowrap;
      }
    }

    &:last-child {
      flex: 0;

      .bottom {
        margin-right: 0;
      }

      .line {
        display: none;
      }
    }

    .top {
      display: flex;
      align-items: center;

      .circle {
        background-color: $sma-light-gray;
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 50%;
      }

      .line {
        flex: 1;
        background-color: $sma-light-gray;
        height: 0.25rem;
        margin: 0 0.125rem;
      }
    }

    &.active {
      .top {
        .circle,
        .line {
          background-color: $sma-blue;
        }
      }

      .bottom {
        .text {
          color: $sma-black;
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    flex-direction: column;

    .step {
      display: flex;
      align-items: center;
      margin: 0.2rem 0;

      &:last-child {
        flex: 1;
      }

      .bottom {
        margin: 0 0.5rem;

        .text {
          display: inline-block;
          margin-right: 0.2rem;
        }
      }
    }

    .line {
      display: none;
    }
  }
}

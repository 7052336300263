
                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                























































































@import './css/styles.scss';

#app {
  height: 100%;
  position: relative;

  .header {
    z-index: 10;
  }

  .page {
    z-index: 5;
  }

  .footer {
    z-index: 1;
  }
}

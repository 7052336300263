
                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                































































.aspect-16-9 {
  position: relative;
  width: 100%;
  padding-top: 56.25%;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.post-preview {
  .post-preview-img-container {
    width: 100%;
  }

  .post-preview-img {
    object-fit: cover;
    object-position: center;
  }

  .post-preview-title {
    margin: 0;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .post-preview-date {
    font-size: 16px;
  }

  &.wide {
    @media only screen and (min-width: $breakpoint-md) {
      display: flex;
      align-items: center;

      .post-preview-img-container {
        width: 256px;
        flex: none;
      }

      .text-container {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;
      }

      .post-preview-date {
        order: 0;
      }

      .post-preview-title {
        order: 1;
        margin-bottom: 0;
      }
    }
  }
}

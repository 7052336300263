
                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                



























































img {
  max-width: 100%;
}

.half {
  width: 50%;
  float: left;
  margin-right: 1rem;
}

.raj {
  width: 22%;
  float: right;
  margin-left: 1rem;
}

.posters {
  width: 80%;
  margin: 0 auto;
  display: block;
}

.indent {
  margin-left: 3rem;
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                































































.form {
  @media only screen and (max-width: $breakpoint-sm) {
    max-width: 90%;
  }
}

.button {
  display: block;
  cursor: pointer;
  padding: 0.5rem 4rem;
  margin: 0.5rem auto;
  border: 1px solid $sma-yellow;
  color: $sma-yellow;
  border-radius: 10rem;
  white-space: nowrap;
  text-align: center;
}

.error-message {
  margin: 0.5rem 0;
  font-size: 0.875rem;
  color: $sma-red;
}

.element {
  margin-top: 1.2rem;
  width: 85vw;
  height: 75vh;
}

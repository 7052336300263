
                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                








































































.airport-select {
  font-size: 0.875rem;
  margin: 0.5rem 0;

  ::v-deep .vs__search::placeholder {
    color: #bbb;
    font-weight: 400;
  }
}

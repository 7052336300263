
                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                















































































































































































































































































































































































































.form {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  max-width: 600px;
}

.sign-up-text {
  font-size: 28px;
  margin-bottom: 16px;
  text-align: center;
  font-weight: 700;
}

.top-blurb {
  font-size: 20px;
}

.btn {
  &.facebook {
    color: $sma-white;
    background-color: $facebook-blue;
    border-color: $facebook-blue;
  }
}

.divider {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;

  .line {
    background-color: $sma-light-gray;
    height: 2px;
  }

  .text {
    align-self: center;
    margin-top: -0.75rem;
    padding: 0 1.5rem;
    background-color: $sma-white;
    font-size: 0.875rem;
  }
}

.input {
  border: 1px solid $sma-light-gray;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
  font-size: 0.875rem;

  @media only screen and (max-width: $breakpoint-sm) {
    width: 100%;
  }
}

.link {
  color: $sma-blue;
  font-weight: 600;
}

.forgot-password {
  align-self: center;
  margin: 0.5rem 0;
  font-size: 0.875rem;
}

.login {
  align-self: center;
  font-size: 0.875rem;
}

.error-message {
  margin: 0.5rem 0;
  font-size: 0.875rem;
  color: $sma-red;
}

.input-row {
  display: flex;

  @media only screen and (max-width: $breakpoint-sm) {
    display: block;
  }
}

.first-name {
  flex: 1;
  margin-right: 0.5rem;

  @media only screen and (max-width: $breakpoint-sm) {
    margin-right: 0;
  }
}

.last-name {
  flex: 1;
  margin-left: 0.5rem;

  @media only screen and (max-width: $breakpoint-sm) {
    margin-left: 0;
  }
}

input.error {
  border-color: red;
}

.media-container {
  position: relative;
}

.media {
  margin: 0.5rem 0;
}

.x-button {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2rem;
  cursor: pointer;
}

#email {
  position: absolute;
  left: -10000vw;
  top: -10000vh;
}

.single-line {
  align-items: center;
}

.label[for="isDiscoverable"] {
  margin-right: 16px;
}

.is-discoverable {
  margin-top: 4px;
}

.bio {
  min-height: 80px;
}

#isDiscoverable::v-deep {
  .v-switch-core {
    background-color: $sma-gray;
  }

  &.toggled {
    .v-switch-core {
      background-color: $sma-blue;
    }
  }
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                












































































































































































































































































.row {
  margin-bottom: 1rem;
}

.form .col {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  margin: 0.5rem 0;

  .label {
    margin-bottom: 0.25rem;
  }

  &.horizontal {
    flex-direction: row;
    align-items: center;

    .label {
      margin-bottom: 0;
      margin-left: 1rem;
    }
  }
}

.input {
  border: 1px solid $sma-light-gray;
  border-radius: 0.25rem;
  padding: 4px 8px;
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  display: block;

  &:focus {
    border-color: $sma-blue;
  }
}

.button {
  align-self: center;
  color: $sma-white;
  background-color: $sma-yellow;
  border-radius: 10rem;
  padding: 0.5rem 2rem;
  margin-top: 5rem;
}

.delete-button {
  background-color: red;
}

.media-container {
  position: relative;
}

.media {
  margin: 0.5rem 0;
}

.x-button {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2rem;
  cursor: pointer;
}

#email {
  position: absolute;
  left: -10000vw;
  top: -10000vh;
}

.single-line {
  align-items: center;
}

#isCommercialVSelect {
  width: 256px;
}

.label[for="isCommercial"],
.label[for="isDiscoverable"] {
  margin-right: 16px;
}

#isDiscoverable::v-deep {
  .v-switch-core {
    background-color: $sma-gray;
  }

  &.toggled {
    .v-switch-core {
      background-color: $sma-blue;
    }
  }
}

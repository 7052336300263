
                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                
















































































































































































.post-media-page {
  padding: 2rem;
}

.row {
  margin-bottom: 2rem;
}

.media {
  margin: 0.5rem 0;
}

.nav .col {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  margin: 0.5rem 0;

  .button {
    align-self: center;
    margin-top: 3rem;

    @media only screen and (max-width: $breakpoint-sm) {
      margin-top: 0.5rem;
      width: 100%;
    }
  }
}

[default-media] {
  box-shadow: 0 0 20px 10px $sma-blue;
}

.fail {
  font-size: 0.875rem;
  color: red;
  margin-left: 1rem;
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                










































































































































.shrink {
  position: relative;
  width: 100%;
  float: left;
  margin-top: 1.2rem;
  margin-right: 1rem;
  cursor: pointer;
}

.videos img {
  width: 100%;
  height: auto;
  margin-bottom: 2rem;
}

a.video {
  float: left;
  position: relative;
}

a.video span {
  width: 100%;
  height: 100%;
  position: absolute;
  background: url(https://img.icons8.com/color/480/000000/youtube-play.png) no-repeat;
  background-position: 50% 38%;
  background-size: 20%;
}

@media screen and (max-width: 480px) {
  a.video span {
    background-size: 20%;
  }
}

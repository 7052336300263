
                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                





















































































.button {
  display: block;
  text-align: center;
  color: $sma-white;
  background-color: $sma-yellow;
  padding: 0.5rem 2rem;
  border-radius: 10rem;
  margin: 1rem auto;
  width: 50%;
  border: 0;
}

.container {
  margin-bottom: 60px;
}

.input {
  border: 1px solid $sma-light-gray;
  border-radius: 0.25rem;
  padding: 4px 8px;
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  font-size: 1em;
  width: 100%;
  display: block;

  &:focus {
    border-color: $sma-blue;
  }
}

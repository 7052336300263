.aspect-ratio-1-1,
.aspect-ratio-3-2,
.aspect-ratio-16-9 {
  &::before {
    content: '';
    float: left;
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

.aspect-ratio-1-1::before { padding-top: 100%; }
.aspect-ratio-3-2::before { padding-top: 66.667%; }
.aspect-ratio-16-9::before { padding-top: 56.25%; }


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                



























































































































































































img {
  max-width: 100%;
  width: 100%;
}

.member-avatar {
  position: relative;
  padding-top: (9 / 16) * 100%;
  overflow: hidden;

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.member-name {
  color: $sma-blue;
  font-weight: 500;
  font-size: 22px;
}

.member-title {
  font-style: italic;
}

.member-blurb p {
  line-height: 1;
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                









































































.shrink {
  width: 100%;
  float: left;
  margin-top: 1.2rem;
  margin-right: 1rem;
  font-weight: bold;
}

.btn {
  font-weight: bold;
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                











































































































































































































































































































































































































































.home-page .slider-pagination {
  display: none;
}

.slider-item > div {
  width: 100%;
}

/* Added to fix IE 11 wrapping issue */
.col-flex {
  flex: 1;
}

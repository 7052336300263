
                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                



































.sidebar {
  display: none;

  @media (min-width: $breakpoint-sm) {
    display: block;
  }

  .col {
    border-bottom: 1px $sma-light-gray solid;
    padding: 1rem 0;
    max-width: 220px;

    &:last-child {
      border: none;
    }

    & a {
      display: flex;
      align-items: center;
      justify-content: left;
    }
  }

  & img {
    max-height: 48px;
    max-width: 48px;
    margin: 0 0.8em 0 0;
  }

  & h1 {
    font-size: 1.2em;
    color: $sma-blue;
    font-weight: 500;
  }
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                




















































































































































.rotate-buttons {
  position: absolute;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;

  .rotate-button {
    background: $sma-black;
    border-radius: 1em;
    height: 1.5em;
    width: 1.5em;
    display: flex !important;
    float: left;
    align-items: center;
    justify-content: center;
    margin: 0 0 4px 8px;

    .icon {
      color: $sma-white;
    }
  }
}

.media-thumbnail {
  position: relative;
  display: flex;
  width: 100%;
}

.del {
  position: absolute;
  top: 0;
  right: 0;
  color: $sma-white;
  background: $sma-black;
  border-radius: 1em;
  height: 1.2em;
  width: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
}

.media {
  margin: 0.5rem 0;
  width: 100%;
}

.vid-wrapper {
  display: flex;
  width: 100%;
}

iframe {
  width: 100%;
  max-width: 100%;
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                




















































































.map-container {
  position: relative;
  width: 100%;
}

.map {
  position: relative;
  padding-bottom: calc(100% * 9 / 16);
}

.map > div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}


                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                























































.button {
  cursor: pointer;
  padding: 0.25rem 4rem;
  margin: 0.5rem 0;
  border-radius: 10rem;
  white-space: nowrap;
  border: 1px solid $sma-yellow;
  text-align: center;

  /*
  &:hover {
    background-color: $sma-yellow;
    color: $sma-white;
  }
  */
}
